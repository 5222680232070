html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  color: #34313f;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.3334;
  margin-top: 20px;
  margin-bottom: 30px;
}

h2 {
  background: 0 0;
  border: 0 !important;
  color: #34313f;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3334;
  margin-top: 10px !important;
  padding: 0 !important;
}

h3 {
  background: 0 0;
  border: 0 !important;
  color: #34313f;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3334;
  margin-top: 10px !important;
  padding: 0 !important;
  margin-bottom: 20px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fbfbfc;
  border-top: 1px solid #d7d6d9;
  padding: 0;
  color: black;
  text-align: center;
  z-index: 3;
}

.footer li {
  display: inline-block;
  list-style: none;
  padding: 10px 0px 0 10px;
  margin-bottom: -5px;
}

.btn {
  line-height: 18px;
  height: 35px;
  padding: 5px 9px;
  text-align: center;
  background-color: #4b71fc;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  width: auto;
  border-style: none;
}

.create-btn {
  font-weight: 500;
  padding: 0 14px;
}
.cancel {
  color: #4b71fc;
  margin: 0px 15px 0 0px;
  padding-top: 1px;
}

.f-right {
  text-align: right;
}

.body-container {
  background-color: #fff;
  border: 1px solid #e4e7ed;
  margin: 0 0 15px 0;
  padding: 20.001px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px 2px #e4e7ed;
  -moz-box-shadow: 0 0 5px 2px #e4e7ed;
  box-shadow: 0 0 5px 2px #e4e7ed;
}
.last {
  margin: 0 0 72px 0;
}

.inline-flex {
  display: inline-flex;
  clear: both;
}
.field-xlarge,
input.field-xlarge {
  width: 470px;
}
.spinner-border {
  color: #3c64f4;
}
.spinner-pos {
  position: fixed;
  bottom: 50%;
  z-index: 999999;
}
.spinner-margin {
  margin-top: 20%;
  margin-left: 50%;
}

.select-type {
  width: 100%;
  border-color: lightgray;
  border-radius: 4px 4px 4px 4px;
  /* styling */
  background-color: white;
  border-radius: 3px;
  display: inline-block;
  font: inherit;
  line-height: 1.25em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgb(168, 168, 168) 50%
    ),
    linear-gradient(135deg, rgb(165, 165, 165) 50%, transparent 50%),
    linear-gradient(to right, rgb(255, 255, 255), #fbfbfc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  text-align: right;
  padding: 10px 20px 0px 0;
  font-size: 14px;
}
.form-group label.adv-form-label {
  text-align: left;
  padding: 10px 20px 0px 0;
  color: rgb(49, 52, 64);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0px 0px 0.25rem;
  cursor: pointer;
  display: inline-block;
}
.form-group label.adv-form-label-bold {
  text-align: left;
  padding: 20px 20px 15px 0;
  color: rgb(49, 52, 64);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0px 0px 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
}
.date-label {
  background: rgb(255, 225, 128);
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.help-format {
  float: right;
  font-size: 11px;
  padding: 5px 0px 5px 0;
  width: 80%;
  color: #aeadb3;
}
.customer-group-radio-ul {
  text-align: left;
  margin: 0px;
  padding: 0;
}
.customer-group-radio-li {
  list-style: none;
}
input[type="radio"] {
  transform: scale(2);
}
.customer-group-radio-li label {
  padding-left: 20px;
  font-size: 14px;
}
#customerTarget {
  background: rgb(255, 225, 128);
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
#targetingDIVPAdding {
  margin-top: 20px;
}
ul.targetList {
  margin-top: 20px;
}
ul li.targetList::marker {
  color: rgb(255, 225, 128);
  font-size: 25px;
}
#currencyIs {
  background: rgb(246, 247, 252);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.inline-display {
  display: -webkit-inline-box;
}
.top-pad {
  padding-top: 6px;
}
.date-div-space {
  margin-bottom: 15px;
}
.div-left-pad {
  padding-left: 15px;
}
.div-right-pad {
  padding-right: 15px;
}
.btn-light {
  background-color: #fff;
  color: #4b71fc;
  margin-right: 20px;
}
.btn-light:hover {
  background-color: rgb(219, 227, 254);
  color: #4b71fc;
}
.btn-group-light {
  padding-left: 40px;
}
.btn-group-light button svg {
  margin-top: -5px;
}
.delete-row-section {
  text-align: right;
}
.delete-icon {
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  visibility: visible;
  -webkit-box-align: center;
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.25rem;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 2rem;
  pointer-events: auto;
  position: relative;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  background-color: transparent;
  color: rgb(60, 100, 244);
  transition: background-color 150ms ease-out 0s, border-color, box-shadow,
    color;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 0.25rem;
  flex: 0 0 auto;
  outline: none;
  text-decoration: none;
  padding: 0px 0.5rem;
  border-color: transparent;
}
.delete-btn-icon {
  -webkit-box-align: center;
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.25rem;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 2rem;
  pointer-events: auto;
  position: relative;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  background-color: transparent;
  color: rgb(60, 100, 244);
  transition: background-color 150ms ease-out 0s, border-color, box-shadow,
    color;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 0.25rem;
  flex: 0 0 auto;
  outline: none;
  text-decoration: none;
  padding: 0px 0.5rem;
  border-color: transparent;
  width: auto;
}
.delete-btn-icon:hover {
  background-color: rgb(219, 227, 254);
}

.gray-label {
  background: rgb(246, 247, 252);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 16px !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem !important;
  width: auto;
}
.text-r-align {
  text-align: right;
}
.dot-svg svg {
  margin: 18px 0 0 18px;
}
.hideme {
  display: none;
}
.addTargetRile {
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  visibility: visible;
}
.targetingCurrency {
  margin-left: -25px;
  width: 19%;
  margin-right: 20px;
}
.deleteTargetingElement {
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  visibility: visible;
}
.deleteBTN {
  display: contents;
}
.isIsNotSelect {
  width: 80%;
}
.displayFlex {
  display: flex;
}
.has-search .form-control {
  padding-left: 2.5rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.marginTB {
  margin-top: -7px;
  margin-bottom: 8px;
}
.form-control:disabled,
.form-control:read-only {
  background-color: #ffffff;
}
.isIsNotCon {
  width: 36%;
  margin-right: -25px;
}
.deleteIconDiv {
  float: right;
  margin-top: 10px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.addRule {
  font-size: 12px;
  color: gray;
}
.modal-content {
  width: 150%;
  height: 500px;
  margin-left: -126px;
}
.usageLimitSpan {
  font-weight: 500;
}
.radioLabel {
  margin-right: 20px;
}
.counterEl {
  width: 10%;
  /* border-block-color: blue; */
  border: 2px solid #4b71fc;
  margin-top: 10px;
}
.checkboxSize {
  width: 20px;
  height: 20px;
}
.checkboxLabel {
  padding: 0 !important;
}

.counter-day > div {
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  height: 41px;
  margin: 0px 1px 0px 0px;
}

.hr-line {
  border: 1px solid gray;
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
  background-color: #fff !important;
}
.cancel-alert-model-content {
  height: inherit !important;
  width: 150% !important;
}
.cancel-alert-model-body {
  padding: 20px;
  height: 190px;
}
.model-myfooter {
  text-align: right;
  padding: 20px 0px 0px 13px;
}
.custom-btn-width {
  width: 65px;
}
.custom-btn-width:hover {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.cancel-alert-model-text {
  padding-top: 10px;
}
#deleteModal.fade,
#individualProductModalPopup.fade,
#allCategoryModalPopup.fade,
#allBrandModalPopup.fade,
#zoneListModalPopup.fade {
  background: rgba(49, 52, 64, 0.5);
}
.marginBottom25 {
  margin-bottom: 25px;
}
.marginLeft10 {
  margin-left: 10px;
}
.width250 {
  width: 250%;
}
.top-pad-15 {
  padding-top: 15px;
}
.top-pad-12 {
  padding-top: 12px;
}
.top-pad-5 {
  padding-top: 5px;
}
.bottom-pad-15 {
  padding-bottom: 15px;
}
.top-bottom-10 {
  padding-bottom: 10px;
}
.top-bottom-5 {
  padding-bottom: 5px;
}
.padding-left-8px {
  padding-left: 8px !important;
}
.rule-select-div {
  width: 247px;
  margin-top: -7px;
}
.currency-inputt-dollor-symbol {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.currency-input {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 38px;
}

.rule-counter > div {
  height: 38px;
}
.rule-counter-wrap {
  padding: 0px 0 0 13px;
}
.break-sentance {
  width: 92px;
}
.wrap-inc-prod label {
  text-align: left;
}
.indivisual-search-box > svg {
  margin-left: 10px;
  width: 17px !important;
  color: #666 !important;
}
.selectCountryInput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.individual-prod-alert-model-body input[type="checkbox"],
.all-brand-alert-model-body input[type="checkbox"],
.all-category-alert-model-body input[type="checkbox"],
.all-zone-alert-model-body input[type="checkbox"] {
  transform: scale(1.5);
}
.individual-prod-alert-model-body .table,
.all-brand-alert-model-body .table,
.all-category-alert-model-body .table,
.all-zone-alert-model-body .table {
  border-color: #ddd !important;
}
.individual-prod-alert-model-body .table tr td,
.all-brand-alert-model-body .table tr td,
.all-category-alert-model-body .table tr td,
.all-zone-alert-model-body .table tr td {
  height: 60px;
  vertical-align: middle;
  padding: 5px 15px;
  word-break: break-all;
}
.individual-prod-alert-model-body .table thead tr th,
.all-brand-alert-model-body .table thead tr th,
.all-category-alert-model-body .table thead tr th,
.all-zone-alert-model-body .table thead tr th {
  color: lightgray;
  font-weight: 500;
}

.modal-body .individual-prod-alert-model-text,
.modal-body .all-brand-alert-model-text,
.modal-body .all-category-alert-model-text,
.modal-body .all-zone-alert-model-text {
  height: 350px;
  overflow-y: auto;
}
#searchKey {
  margin-top: -33px;
}
.individual-prod-alert-model-text .has-search .form-control-feedback,
.all-brand-alert-model-text .has-search .form-control-feedback,
.all-category-alert-model-text .has-search .form-control-feedback,
.all-zone-alert-model-text .has-search .form-control-feedback {
  position: relative;
  top: 5px;
}
.loader-wraper {
  text-align: center;
}
.spinner-border {
  color: blue;
}
.width-100 {
  width: 100%;
  margin-top: 5px;
}
.width-10 {
  width: 10%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-25 {
  width: 25%;
}
.width-40 {
  width: 40%;
}
.width-55 {
  width: 55%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
#individualProductModalPopup .modal-content,
#allBrandModalPopup .modal-content,
#allCategoryModalPopup .modal-content,
#zoneListModalPopup .modal-content {
  max-height: 500px;
  height: auto;
}
.selected-lebel .li-item {
  display: inline;
  padding: 5px;
  margin: 5px 5px;
  background-color: rgb(217, 220, 233);
  display: inline-block;
}
.block-ui-message-container {
  top: 50px !important;
}
.margin-50-left {
  margin-left: 50px;
}
.gray-and .gray-label {
  width: auto;
}
.another-inclution {
  margin-top: 15px;
}
.reward-once-select-div {
  width: 247px;
  margin-top: -7px;
  margin-left: 8px;
}
.mid-gray-label {
  width: auto;
  margin-top: -7px;
}
.last-wide-gray-label {
  width: 230px;
}
.auto-width {
  width: auto !important;
}
.auto-width .input-group-prepend {
  height: 41px;
  padding-left: 8px;
}
.auto-width .currency-inputt-dollor-symbol {
  height: 41px !important;
}
.auto-width .currency-input {
  height: 41px !important;
}
.discount-Percent {
  padding-left: 8px;
}
.discount-Percent .percent-input {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  border-left: 1px solid #ccc;
  border-right: 0px #fff;
}
.input-percent-apend .currency-input-percent-symbol {
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 41px;
}
.upto-select > div {
  height: 41px;
}
.inc-product-check input[type="checkbox"] {
  transform: scale(1.5);
}
.rm-padding-left {
  padding-left: 0px !important;
}
.error {
  border: 1px solid #cc0000;
  border-radius: 5px;
}
.width14 {
  width: 14% !important;
}
.width16 {
  width: 16% !important;
}
a {
  color: #0a58ca;
  text-decoration: none;
  /*border-bottom: 1px solid #0a58ca;*/
}
.quantity-counter {
  height: 40px;
}
.discount-Percent .error {
  border-left: 1px solid #cc0000 !important;
}
.currency-inputt-dollor-symbol.error {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.currency-input.amount-input.error {
  border-left: none;
}
.currency-input.error{
  border-left: none;
}
.input-cur-amt.error{
  border-left: 1px solid #cc0000;
}
.countryChip {
  background-color: rgb(217, 220, 233);
  border-radius: 2px;
  color: rgb(49, 52, 64);
  display: inline-flex;
  line-height: 1.5rem;
  margin: 0.25rem;
  padding: 0px 0.5rem;
  z-index: 1;
}

.alertContainer {
  z-index: 99999999999;
  float: right;
  position: fixed;
  margin: 10px 63px;
  right: 0;
}
.closeAlertButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 11px;
  cursor: pointer;
}

.counter-pad-margin{
  padding-bottom: 0;
  height: 43px;
  margin-left: 8px;
}
.counter-day.rule-counter.upto-select.error{
  height: 43px;
}
.couponQty {
  margin-left: 10%;
}
.center-aligment{
  text-align: center;
}
.right-aligment{
  text-align: right;
}
.revert-aligment{
  text-align: revert;
}
.counter-day div div:first-child:after
{
  content: "\f056";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  color: #4b71fc;
  font-size: 20px;
  padding: 0 5px 0 0px;
}
.counter-day div div:last-child:after
{
  content: "\f055";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  color: #4b71fc;
  font-size: 20px;
  padding: 0 5px 0 0px;
}
.counter-day div div:last-child, .counter-day div div:first-child{
  color:#fff;
  font-size: 0px;
}
.prefixSpan {
  font-weight: 500;
    font-size: 11px;
}
.prefixCoupon{
  text-transform: capitalize;
}
.header-create-promotion-sec{
  text-align: center;
}
.message-container{
  text-align: center;
}
.padding-right-25{
  padding-right: 25px !important;
}

@media (min-width: 576px){
.col-sm-1 {
  flex: 0 0 auto;
  width: 9.333%;
}
}