@import "./variables";

.visual-bundle-container {
  padding: $p-20;
  margin-top: 25px;
}

.table-update-btn {
  background-color: $btn-bg-color;
  color: $btn-color;
  border-style: none;
  padding: 5px 40px;
}

.table-delete-btn {
  margin-left: $ml-20;
}

.table-delete-btn:hover {
  cursor: pointer;
}

.vb-table .table-bordered th,
.table-bordered td {
  border: none;
}
.vb-table {
  border: 1px solid $solitude !important;
}
.rules-table {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;

  // width: 50%;
}
.rules-table thead {
  background-color: #c3d0ff2e !important ;
  color: #757575;
}

/* .table tr > th:last-of-type{
    border-left: none;
} */
.table th {
  border: none;
  font-weight: 600;
  /* color: #f6f7f9; */
}
.table thead {
  background-color: $table-head-bg-color;
}
.table tr > :nth-child(5) {
  border-right: none;
}
.table tbody tr {
  border-bottom: 1px solid $solitude;
}
.icon-left {
  padding-right: $pr-20;
}
.icon-container svg {
  color: $azul;
}
.icon-container {
  float: right;
}
.spinner-border {
  color: $azul;
}
.spinner-pos {
  position: fixed;
  bottom: 50%;
  z-index: 999999;
}

.vb-table {
  border: 1px solid $lightgray !important;
}

.nav-highlight {
  float: left;
  border: 5px;
  border-bottom-style: solid;
  width: 62px;
  text-align: left;
  border-color: $azul;
}

.clear {
  clear: both;
}

.stepper div div div :first {
  background-color: $azul !important;
  padding: $p-0 !important;
}

.heading1 {
  font-weight: normal;
  size: 26px;
  line-height: 38px;
  float: left;
  padding: 17px 0 0 0;
}

.heading2 {
  font-weight: 600;
  size: 14px;
  line-height: 22px;
}

.nav-head {
  font-weight: normal;
  size: 24px;
  line-height: 36px;
}

.body1 {
  font-weight: normal;
  size: 24px;
  line-height: 36px;
}

.body2 {
  font-weight: normal;
  size: 14px;
  line-height: 22px;
}

.head-title {
  display: inline-flex;
  padding-bottom: $pb-30;
}

.inline-flex {
  display: inline-flex;
  clear: both;
}

.control-label {
  text-align: right;
  padding: $p-5;
}

.avail-radio-li {
  list-style: none;
}

.avail-radio-ul {
  text-align: left;
  padding: $p-0;
}

.avail-radio-li label {
  padding-left: $pl-20;
  font-size: 14px;
  vertical-align: middle;
}

.select-type {
  width: 100%;
  border-color: $lightgray;
  border-radius: 4px 4px 4px 4px;
  /* styling */
  background-color: $white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $bg-img-linear-gradient-168 50%
    ),
    linear-gradient(135deg, $bg-img-linear-gradient-165 50%, transparent 50%),
    linear-gradient(
      to right,
      $bg-img-linear-gradient-255,
      $bg-img-linear-gradient-255
    );
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.text-area-box {
  min-height: 100px;
}

.next-button {
  line-height: 18px;
  height: 40px;
  padding: 5px 9px;
  text-align: center;
  background-color: $btn-bg-color;
  color: $btn-color;
  border-radius: 2px;
  display: inline-block;
  width: 146px;
  border-style: none;
}

.back-button {
  line-height: 18px;
  height: 40px;
  padding: 5px 9px;
  text-align: center;
  background-color: $form-control-bg-color;
  color: $black;
  border-radius: 2px;
  display: inline-block;
  width: 146px;
  border-style: none;
}

.button-alignment {
  text-align: right;
  padding-top: 25px;
}

.button-alignment .back-button {
  margin-right: 30px;
}

.excluding-tax {
  color: $text-placeholder-color;
  text-align: left;
  padding: 10px;
}

text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-placeholder-color;
}

input[type="radio"] {
  transform: scale(1.5);
}

.visible-group {
  text-align: left;
}

.visible-group label {
  padding-left: 20px;
  vertical-align: middle;
}

.visible-group > input[type="checkbox"] {
  transform: scale(1.5);
}

.help-format {
  float: left;
  font-size: 11px;
  padding: 5px 0px 5px 0;
}

button:disabled {
  cursor: not-allowed;
  background-color: $lightgray;
}
a[disabled] {
  color: $lightgray;
  cursor: not-allowed;
}
ol li {
  list-style: none;
}

.categories {
  max-height: 218px;
  overflow: auto;
  text-align: left;
  padding-bottom: 15px;
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: block !important;
}

#bundleDefaultPrice,
#bundleSalePrice {
  border-left: none;
  padding: 0px;
}
#basic-addon {
  border-right: none;
  background: none;
}
.input-group {
  background: none;
  height: inherit !important;
  height: 100% !important;
  min-height: inherit !important;
  min-height: 100% !important;
}
.error {
  border: 1px solid $error-border-color;
}

.input-group-prepend > #basic-addon.error {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.bundle_create_error {
  color: $error-color;
  float: right;
}
.success_message {
  color: $success-color;
  font-weight: bold;
  margin-left: $ml-10;
}
.header-container {
  margin: 10px;
  vertical-align: middle;
  align-content: center;
  padding-bottom: 20px;
}
.create-bundle-btn {
  background-color: $btn-bg-color;
  color: $btn-color;
  padding: 10px 30px;
  border-style: none;
}
.message-container {
  padding: 20px 40px 20px;
}

.react-checkbox-tree ol {
  list-style: none;
}
.react-checkbox-tree ol > li {
  position: relative;
}

.react-checkbox-tree ol > li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-left: 1px dotted $black;
  margin-left: $ml-5;
  height: 100%;
}
.react-checkbox-tree ol > li:first-of-type:after {
  top: 25%;
}
.react-checkbox-tree ol > li:last-of-type:after {
  top: -25%;
}

.rct-icons-fa4 .rct-icon-expand-close::before {
  content: "+" !important;
  font-size: 15px !important;
}
.rct-icons-fa4 .rct-icon-expand-open::before {
  content: "-" !important;
  font-size: 15px !important;
}
.rct-icons-fa4 .rct-icon-uncheck::before {
  content: " " !important;
  width: 0px !important;
}
.rct-icons-fa4 .rct-icon-parent-close::before {
  content: " " !important;
  width: 0px !important;
}
.rct-icons-fa4 .rct-icon-parent-open::before {
  content: " " !important;
  width: 0px !important;
}
.rct-icons-fa4 .rct-icon-leaf::before {
  content: " " !important;
  width: 0px !important;
}
.rct-icons-fa4 .rct-icon-check::before {
  content: " " !important;
  width: 0px !important;
}
.react-checkbox-tree label {
  display: inline-flex !important;
  margin-bottom: 2px !important;
}
.rct-title {
  padding: 0 1px !important;
  font-size: 13px;
}
.react-checkbox-tree:not(.rct-native-display) input {
  margin-top: 5px;
}
.rct-checkbox,
.rct-node-icon {
  width: 0px;
}
.react-checkbox-tree .rct-icon-half-check::before {
  width: 0px;
  content: " " !important;
}
.rct-icons-fa4 .rct-icon-expand-close {
  margin: 0 0 0 -2px !important;
}
.rct-icons-fa4 .rct-icon-expand-open {
  margin: 0 0 0 -2px !important;
}
.rct-node-leaf .rct-collapse .rct-icon {
  width: 8px;
  margin: 0 0 0 4px !important;
}

.categories::-webkit-scrollbar,
.rdl-control::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
.categories::-webkit-scrollbar-track,
.rdl-control::-webkit-scrollbar-track {
  background: $tracking-area-color; /* color of the tracking area */
}
.categories::-webkit-scrollbar-thumb,
.rdl-control::-webkit-scrollbar-thumb {
  background-color: $scrollthumb-bg-color; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid $scrollthumb-border-color; /* creates padding around scroll thumb */
}

.error-item-ul li {
  list-style: none;
  font-size: 13px;
}
.right-aligment {
  text-align: right;
}
.left-aligment {
  text-align: left;
}
.success-color {
  color: $success-color;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.spinner-margin {
  margin-top: 20%;
}
.unit-input {
  width: 80px;
  border-radius: 0px;
  display: inline-block;
  margin-left: 14px;
}

#headerContainer {
  padding: 20px 0px;
}

.title-head {
  font-weight: 600;
  font-size: 14px;
}

.text-align-center {
  text-align: center;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $form-control-bg-color !important;
}

.rdl-control-container {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 325px;
}

.rdl-actions {
  margin: 0px 10px 0px 0px !important;
}
select.rdl-control > option {
  min-height: 25px;
  vertical-align: middle;
  font-size: 13px;
}

.select-body-container {
  padding: 0px 15px 0px 15px;
}
#selectHeaderContainer {
  padding-bottom: 10px;
}
#bundleCreatorHeaderContainer {
  padding: 0px 0 10px 0px;
}

.tooltip > div.tooltip-inner {
  position: relative;
  left: 80%;
  top: -12px;
  background-color: $white !important;
  color: $tooltip-color !important;
  border: 1px solid $tooltip-border-color;
  -webkit-box-shadow: 0px 1px 5px 2px $tooltip-border-color;
  -moz-box-shadow: 0px 1px 5px 2px $tooltip-border-color;
  box-shadow: 0px 1px 5px 2px $tooltip-border-color;
  border-radius: 25px;
  text-align: left;
  padding: 10px;
  width: auto;
}

.tooltip > div.tooltip-inner {
  max-width: 100%;
}
.tooltip > div.tooltip-inner > span {
  list-style: none;
  padding: 5px 30px 5px 10px;
  margin: 0;
  text-align: left;
  clear: both;
  display: flex;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > div.arrow::before {
  display: none;
}

.modal-backdrop {
  display: none;
}

.update-th-width {
  width: 15%;
}
.price-th-width {
  width: 10%;
}
.delete-th-width {
  width: 10%;
}
.th-name-width {
  width: 22%;
}
.th-sku-width {
  width: 20%;
}
.th-category-width {
 /* width: 12%;*/
}
.sku-cell-padding {
  padding-left: 30px !important;
}
.dtc-header-container {
  margin: 10px 10px 0 10px;
  vertical-align: middle;
  align-content: center;
  padding-bottom: 20px;
  text-align: center;
}
.dtc-header-container .heading1 {
  font-weight: normal;
  size: 26px;
  line-height: 38px;
  float: none;
  padding: 40px 0 0 0;
}

.dtc-text-content {
  width: 45%;
  font-size: 18px;
  font-weight: 400;
}

.dtc-img-content {
  width: 40%;
  padding: 100px 0 0px 0px;
  font-size: 18px;
}

.img-div img {
  width: 80px;
}
.img-div {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 20px;
}
.img-bottom-text {
  width: 50%;
  padding: 21px 13px 0px 15px;
}

.img-bottom-text .float-left {
  margin: 0px 35px;
  font-weight: 400;
  font-size: 18px;
}

.img-bottom-text .float-right {
  margin: 0 0px;
  font-weight: 400;
  font-size: 18px;
}
.img-bottom-text a {
  text-decoration: none;
  color: $black;
}
.corner-rbbn {
  padding: 10px;
  width: 50%;
  background-image: url("../dtc-images/green_rbbn_style.png");
  background-repeat: no-repeat;
  background-size: 35px, 10px;
}

span.rct-title::before {
  content: "\f07b";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  color: $folderColor;
  padding: 0 5px 0 0px;
}
.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 2px 0 0px !important;
}

button#dualBox-move-right::before {
  content: "Add ";
  font-size: 15px;
}
button#dualBox-move-left::after {
  content: " Remove";
  font-size: 15px;
}
button#dualBox-move-left {
  background-color: $lightgray !important;
  color: $black !important;
}
