//colors 
$white: #FFF;
$solitude: #E3E7ED;
$ghostWhite: #fbfbfc;
$azul: #3C64F4;
$folderColor: #c3d0ff;
$gray : gray;
$white-smoke: #f4f4f4;
$alice-blue: #f6f7f9; 
$dark-blue: #282c34;

$btn-bg-color: $azul;
$btn-color: $white;
$tooltip-color:  $gray;
$tooltip-border-color: rgb(202, 202, 202);
$form-control-bg-color: $alice-blue;
$success-color: #30BF2C;
$scrollthumb-border-color: $solitude;
$scrollthumb-bg-color: #bbb;
$tracking-area-color: rgb(236, 236, 236);
$black: black;
$error-color: red;
$error-border-color: #cc0000;
$lightgray: lightgray;
$text-placeholder-color: $solitude;
$bg-img-linear-gradient-168: rgb(168, 168, 168);
$bg-img-linear-gradient-165: rgb(165, 165, 165);
$bg-img-linear-gradient-255 :rgb(255, 255, 255);
$table-head-bg-color: $ghostWhite;

$header-height: 3.125rem;
$ml-20: 20px;
$ml-15: 15px;
$ml-60: 60px;
$ml-10: 10px;
$ml-5: 5px;
$pr-20: 20px;
$p-0: 0px;
$p-5: 5px;
$pl-20: 20px;
$p-20: 20px;
$pb-30: 30px;


